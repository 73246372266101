<template>
  <div class="weui-tabbar">
    <router-link
      :to="{ name: 'user-index' }"
      class="weui-tabbar__item"
      exact
      exact-active-class="weui-bar__item_on"
    >
      <img
        src="@/assets/image/icons/user.png"
        class="menu-icon"
      >
      <p class="weui-tabbar__label">
        我的
      </p>
    </router-link>
    <router-link
      :to="{ name: 'publish-show' }"
      class="weui-tabbar__item"
      exact
      exact-active-class="weui-bar__item_on"
    >
      <img
        src="@/assets/image/icons/add.png"
        class="menu-icon"
      >
      <p class="weui-tabbar__label">
        发起项目
      </p>
    </router-link>
    <router-link
      :to="{ name: 'index' }"
      class="weui-tabbar__item"
      exact
      exact-active-class="weui-bar__item_on"
    >
      <img
        src="@/assets/image/icons/logo.png"
        class="menu-icon"
      >
      <p class="weui-tabbar__label">
        首页
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
}
</script>

<style lang="scss">
.menu-icon {
  @apply mx-auto mb-1 rounded-full;

  width: 25px;
  height: 25px;
  padding: 4px;
  border: 1px solid #777;
}

.weui-bar__item_on {
  .weui-tabbar__icon {
    color: #fff !important;
    background-color: rgb(221 90 86) !important;
    border-color: rgb(221 90 86) !important;
  }

  .weui-tabbar__label {
    color: rgb(221 90 86) !important;
  }
}
</style>
