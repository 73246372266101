import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  user: null,
  uid: null,
  autologin_token: null,
}

// types
export type AuthState = Partial<typeof state>

// getters
export const getters = {
  check: state => state.user !== null,
}

// mutations
export const mutations = {
  UPDATE_USER (state, value) {
    state.user = value
  },

  UPDATE_UID (state, value) {
    state.uid = value
  },

  UPDATE_AUTOLOGIN_TOKEN (state, value) {
    state.autologin_token = value
  },
}

// actions
export const actions = {
  async fetchUser ({ commit }) {
    // TODO: response type
    // @ts-ignore
    const { code, msg, data: user } = await axios.get('user/me', {
      withCredentials: true,
    })
    if (code === 0) {
      commit('UPDATE_USER', user)
      commit('UPDATE_UID', user.uid)
      commit('UPDATE_AUTOLOGIN_TOKEN', user.autologin_token)
    } else {
      console.error(msg)
    }
    return code
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  logout ({ commit }) {
    commit('UPDATE_USER', null)
    commit('UPDATE_UID', null)
    commit('UPDATE_AUTOLOGIN_TOKEN', null)
  },
}
