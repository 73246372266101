import axios from 'axios'
import store from '@/store'

export default async (to, from, next) => {
  // 除登录页外，每次进入页面时都查询当前用户信息
  const routeExclude = ['auth', 'auth-login-phone']

  if (!routeExclude.includes(to.name)) {
    try {
      // eslint-disable-next-line
      const { data: user } = await axios.get('user/me', {
        called_by_common_middleware: true,
        headers: {
          // 重要：这里要覆盖 axios 实例上原有全局的 header
          // 不然前端已退出登录的用户仍然会用旧的 token 请求到用户数据
          // 解决部分手机上可能无法删除的问题
          uid: store.state.auth.uid,
          token: store.state.auth.autologin_token,
        },
      })

      if (user) {
        store.commit('auth/UPDATE_USER', user)
        store.commit('auth/UPDATE_AUTOLOGIN_TOKEN', user.token)
        store.commit('auth/UPDATE_UID', user.uid)
      }
    } catch (err) {
      // ignore
    }
  }

  next()
}
