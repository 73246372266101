// state
export const state = {
  tabbarVisible: true,
}

// types
export type LayoutState = Partial<typeof state>

// mutations
export const mutations = {
  UPDATE_TABBAR_VISIBLE (state, value) {
    state.tabbarVisible = value
  },
}

// actions
export const actions = {
  /* logout ({ commit }) {
    commit(types.LOGOUT)
  }, */
}
