import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/plugins/axios'
import '@/assets/scss/app.scss' // 引入样式
import { get } from 'lodash'
import mapCurrentMixin from '@/mixins/map-current-user'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

Vue.config.productionTip = false

Vue.prototype.$get = get

// 全局 mixin
Vue.mixin(mapCurrentMixin)

Sentry.init({
  dsn: 'https://65fbf5679cb24f648196d9d7229552b5@sentry.xczb2.com/5',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
})

// 全局变量，是否是灰度调试模式
Vue.prototype.$isDebugMode = store.state.debug.debug_mode

if (process.env.NODE_ENV === 'development') {
  console.log('测试时访问地址要加 /h5 前缀，如：http://localhost:8080/h5/')
}

/* eslint-disable-next-line */
new Vue({
  el: '#app',
  router,
  store,
  ...App,

  mounted () {
    // 用于 wx.config 签名的 URL，ios 设备里始终为项目打开时的 URL
    // 详见：https://github.com/yongheng2016/blog/issues/78
    window.wxConfigUrl = window.location.href.split('#')[0]
  },
})
Vue.component('RemoteScript', {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  render: function (createElement) {
    const self = this
    return createElement('script', {
      attrs: {
        type: 'text/javascript',
        src: this.src,
      },
      on: {
        load: function (event) {
          self.$emit('load', event)
        },
        error: function (event) {
          self.$emit('error', event)
        },
        readystatechange: function (event) {
          if (this.readyState === 'complete') {
            self.$emit('load', event)
          }
        },
      },
    })
  },
})
