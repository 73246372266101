// state
export const state = {
  debug_mode: false,
}

// types
export type DebugState = Partial<typeof state>

// getters
export const getters = {
  isDebugMode: state => state.debug_mode,
}

// mutations
export const mutations = {
  UPDATE_DEBUG_MODE (state, value) {
    state.debug_mode = value
  },
}
