import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// types
import { AuthState } from './modules/auth'
import { AxiosState } from '@/store/modules/axios'
import { LayoutState } from '@/store/modules/layout'
import { ProjectPublishState } from '@/store/modules/project_publish'
import { DebugState } from '@/store/modules/debug'

Vue.use(Vuex)

export interface RootState {
  auth: AuthState
  axios: AxiosState
  layout: LayoutState
  // eslint-disable-next-line
  project_publish: ProjectPublishState
  debug: DebugState
}

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.([jt])s$/)

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.([jt])s$)/g, ''), requireContext(file)],
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

const dataState = createPersistedState({
  key: 'dreamore-h5',
  paths: [
    'debug',
    'auth',
    'project_publish',
  ],
})

const storeOptions: StoreOptions<any> = {
  modules,
  plugins: [dataState],
}

export default new Vuex.Store(storeOptions)
