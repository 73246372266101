import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { API_BASE_URL } from '@/config'
import { get } from 'lodash'
import router from '@/router'
import { Notify } from 'vant'

axios.defaults.baseURL = API_BASE_URL
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

// Request interceptor
axios.interceptors.request.use(config => {
  const uid = store.state.auth.uid
  if (uid) {
    config.headers.uid = uid
  }

  const autologinToken = store.state.auth.autologin_token
  if (autologinToken) {
    config.headers.token = autologinToken
  }

  // 如果是进行“提交”性质操作，则加入提交状态队列
  if ([
    'post',
    'put',
    'patch',
    'delete',
  ].includes(config.method)) {
    store.commit('axios/UPDATE_IS_SUBMITTING', true)
  }
  if (['get'].includes(config.method)) {
    store.commit('axios/UPDATE_IS_LOADING', true)
  }

  return config
})

// Response interceptor
axios.interceptors.response.use(
  async response => {
    // 如果是进行“提交”性质操作，则从提交状态队列中移除
    const method = get(response, 'config.method')

    if ([
      'post',
      'put',
      'patch',
      'delete',
    ].includes(method)) {
      store.commit('axios/UPDATE_IS_SUBMITTING', false)
    }
    if (['get'].includes(method)) {
      store.commit('axios/UPDATE_IS_LOADING', false)
    }

    // 是由 common 中间件请求
    // @ts-ignore
    const calledByCommonMiddleware = response.config.called_by_common_middleware

    if (response.data.code === 300004 && !calledByCommonMiddleware) {
      // 300004 表示 token 失效，需要重新登录
      // 登录成功之后要跳转到网页授权前的地址
      localStorage.setItem('redirectUrlAfterLogin', window.location.href)
      Notify({
        message: '请先登录',
        color: '#fff',
        background: '#ef4949',
      })

      router.replace('/h5/auth')
      return
    }

    return response.data
  },
  error => {
    // 如果是进行“提交”性质操作，则从提交状态队列中移除
    const method = get(error, 'config.method')
    if ([
      'post',
      'put',
      'patch',
      'delete',
    ].includes(method)) {
      store.commit('axios/UPDATE_IS_SUBMITTING', false)
    }
    if (['get'].includes(method)) {
      store.commit('axios/UPDATE_IS_LOADING', false)
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$axios = axios
