<template>
  <div>
    <router-view />
    <Tabbar
      v-if="tabbarVisible"
      class="tabbar"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tabbar from '@/components/Tabbar'

export default {
  name: 'LayoutDefault',

  components: {
    Tabbar,
  },

  data () {
    return {}
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      tabbarVisible: state => state.layout.tabbarVisible,
    }),
  },
}
</script>

<style lang="scss">
.tabbar {
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
