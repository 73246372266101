import { get } from 'lodash'
import axios from 'axios'
import store from '@/store'
import { isWechat } from '@/utils'

export default async (to, from, next) => {
  // url query 里的 token 参数
  const tokenInQuery = get(to.query, 'token')
  const uidInQuery = get(to.query, 'uid')

  // 如果是支付页
  if (to.name === 'project-support-payment') {
    // 支付页而如果链接里带 token 和 uid 参数，不要删除
    // 这样复制出来的链接，在其它浏览器里打开后不需要重新登录，主要用于支付宝支付场景
    // 用户在微信里打开，支持的时候选择支付宝支付，然后复制链接在其它浏览器中打开完成支付
    if (tokenInQuery) {
      // 如果 url 里有 token 参数，则直接把它作为登录凭据
      store.commit('auth/UPDATE_AUTOLOGIN_TOKEN', tokenInQuery)
      store.commit('auth/UPDATE_UID', uidInQuery)

      // eslint-disable-next-line
      const { data } = await axios.get('user/me')
      store.commit('auth/UPDATE_USER', data)
      if (!isWechat()) {
        const newQuery = to.query
        delete newQuery.token
        delete newQuery.uid

        next({
          path: to.path,
          query: newQuery,
        })
      }
    }
  } else {
    // 其它页面如果 query 参数里带 token 和 uid，使用它们作为登录凭据
    // 保存之后清除链接里的 token 和 uid 参数，保证安全性
    if (tokenInQuery) {
      // 如果 url 里有 token 参数，则直接把它作为登录凭据
      store.commit('auth/UPDATE_AUTOLOGIN_TOKEN', tokenInQuery)
      store.commit('auth/UPDATE_UID', uidInQuery)

      // eslint-disable-next-line
      const { data } = await axios.get('user/me')
      store.commit('auth/UPDATE_USER', data)

      // 删除 query 中的 token
      const newQuery = to.query
      delete newQuery.token
      delete newQuery.uid

      next({
        path: to.path,
        query: newQuery,
      })
      return
    }
  }

  // url query 里的 login 参数表示是否已网页授权
  const codeInQuery = get(to.query, 'code')

  const localAuthToken = store.state.auth.autologin_token

  if (codeInQuery) {
    // FIXME 默认值应该根据不同浏览器玩意页不同
    // querystring 里有 code 参数，则是授权登录跳回的，直接使用 code 换取 token
    const oauthType = localStorage.getItem('oauth_type') || 'wechat'

    let apiUrl
    switch (oauthType) {
      case 'wechat': {
        apiUrl = 'oauth/wechatCallback'
        break
      }
      case 'qq': {
        apiUrl = 'oauth/qqCallback'
        break
      }
      case 'weibo': {
        apiUrl = 'oauth/weiboCallback'
        break
      }
      case 'alipay': {
        apiUrl = 'oauth/alipayCallback'
        break
      }
      default: break
    }

    try {
      // eslint-disable-next-line
      const { data, code, msg } = await axios.get(apiUrl, {
        params: {
          code: codeInQuery,
        },
      })

      if (!code) {
        store.commit('auth/UPDATE_USER', data)
        store.commit('auth/UPDATE_UID', data.uid)
        store.commit('auth/UPDATE_AUTOLOGIN_TOKEN', data.autologin_token)
      }
    } catch (err) {
      alert(JSON.stringify(err))
      console.log(err)
    }

    // 登录成功之后跳转到网页授权前的页面
    location.href = localStorage.getItem('redirectUrlAfterLogin') || '/'
  } else if (!localAuthToken && !codeInQuery && needOauth(to)) {
    // 登录成功之后要跳转到网页授权前的地址
    localStorage.setItem('redirectUrlAfterLogin', window.location.href)

    if (isWechat()) {
      // 如果是在微信浏览器中则使用微信内页授权
      // eslint-disable-next-line
      const { data } = await axios.get(`oauth/wechatLoginUrl?callback=${encodeURIComponent(window.location.href)}`)

      window.location.href = data.url
    } else {
      // 其它浏览器则跳转到登录入口页
      next('/h5/auth')
    }
  } else {
    next()
  }
}

/**
 * 页面是否需要网页授权
 * @param route
 */
function needOauth (route) {
  return route.meta.needAuth !== false
}
