// const IS_PRODUCTION = process.env.NODE_ENV === 'production'

const { origin } = window.location

// const IS_LOCAL_DEV = origin.includes('8080')

const DEFAULT_SHARE_IMAGE = require('@/assets/image/default-share-img.jpg')

// 微信自定义分享的默认图片
export const DEFAULT_SHARE_IMAGE_URL = origin + '/h5' + DEFAULT_SHARE_IMAGE

// 本地开发调试对接线上测试接口
// export const API_BASE_URL = !IS_PRODUCTION ? 'http://test.crowd.dm4t.com/webapi' : `${origin}/webapi`
export const API_BASE_URL = `${origin}/webapi`

// payoff 的状态
// showPayoff 接口返回的数据中 type 字段
export const PAYOFF_TYPE = {
  TYPE_PAYOFF_APPEAL: 1, // 有申诉
  TYPE_PAYOFF_UN_COMMIT: 2, // 未兑现
  TYPE_PAYOFF_TO_BE_CONFIRMED: 3, // 待反馈
  TYPE_PAYOFF_FINISHED: 4, // 已完成，已兑现
  TYPE_PAYOFF_LOSE_EFFECT: 5, // 已失效
}
