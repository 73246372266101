// import * as types from '../mutation-types'
// import axios from 'axios'
// import localforage from 'localforage'

// state
export const state = {
  draft: {},
  previewImages: [], // 缓存的上传图片预览
}

// types
export type ProjectPublishState = Partial<typeof state>

// getters
export const getters = {
  // check: state => state.user !== null,
}

// mutations
export const mutations = {
  UPDATE_DRAFT (state, value) {
    state.draft = value
  },

  UPDATE_PREVIEW_IMAGES (state, value) {
    state.previewImages = value
  },

  UPDATE_DRAFT_UPDATE (state, value) {
    state.updateDraft = value
  },
  UPDATE_PREVIEW_IMAGES_UPDATE (state, value) {
    state.previewImagesUpdate = value
  },
}

// actions
export const actions = {
  // updateUser ({ commit }, payload) {
  //   commit(types.UPDATE_USER, payload)
  // },
}
