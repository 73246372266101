import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/h5/',
    name: 'index',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'index' */ '@/views/index.vue'),
  },

  {
    path: '/h5/auth',
    name: 'auth',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'auth' */ '@/views/auth/index.vue'),
  },
  {
    path: '/h5/auth/login-phone',
    name: 'auth-login-phone',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'auth-login-phone' */ '@/views/auth/login-phone.vue'
      ),
  },

  {
    path: '/h5/project/view',
    name: 'project-view',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'project-view' */ '@/views/project/view.vue'),
  },
  {
    path: '/h5/project/viewdetail',
    name: 'project-viewdetail',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'project-viewdetail' */ '@/views/project/viewdetail.vue'
      ),
  },
  {
    path: '/h5/project/select-payoff',
    name: 'project-select-payoff',
    component: () =>
      import(
        /* webpackChunkName: 'project-select-payoff' */ '@/views/project/select-payoff.vue'
      ),
  },
  // 兑现回报
  {
    path: '/h5/project/send-payoff',
    name: 'project-send-payoff',
    component: () =>
      import(
        /* webpackChunkName: 'project-send-payoff' */ '@/views/project/send-payoff/index.vue'
      ),
  },
  {
    path: '/h5/project/supportShow',
    name: 'project-support-payment',
    component: () =>
      import(
        /* webpackChunkName: 'project-support-payment' */ '@/views/project/support-payment/index.vue'
      ),
  },
  {
    path: '/h5/project/supportList',
    name: 'project-support-list',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'project-support-list' */ '@/views/project/support-list.vue'
      ),
  },
  {
    path: '/h5/project/payoffList',
    name: 'project-payoff',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'project-payoff' */ '@/views/project/payoff.vue'
      ),
  },
  {
    path: '/h5/project/updates',
    name: 'project-updates',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'project-updates' */ '@/views/project/updates.vue'
      ),
  },
  {
    path: '/h5/project/tips-of-completeness',
    name: 'project-tips-of-completeness',
    component: () =>
      import(
        /* webpackChunkName: 'project-tips-of-completeness' */ '@/views/project/tips-of-completeness.vue'
      ),
  },

  // 支付成功和失败提示页
  {
    path: '/h5/payment/success',
    name: 'payment-success',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'payment-success' */ '@/views/payment/success.vue'
      ),
  },
  {
    path: '/h5/payment/error',
    name: 'payment-error',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'payment-error' */ '@/views/payment/error.vue'
      ),
  },

  // 常见问题和使用帮助
  {
    path: '/h5/misc/introduction',
    name: 'misc-introduction',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'misc-introduction' */ '@/views/misc/introduction.vue'
      ),
  },
  {
    path: '/h5/misc/faqsDetail',
    name: 'misc-faqs-detail',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'misc-faqs-detail' */ '@/views/misc/faqs-detail.vue'
      ),
  },

  {
    path: '/h5/misc/customer-service',
    name: 'customer-service',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'customer-service' */ '@/views/misc/customer-service.vue'
      ),
  },
  {
    path: '/h5/misc/supportGuide',
    name: 'support-guide',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'support-guide' */ '@/views/misc/guide.vue'),
  },
  {
    path: '/h5/misc/withdraw',
    name: 'misc-withdraw',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: misc-withdraw' */ '@/views/misc/withdraw.vue'
      ),
  },

  // 其它 misc/:id，不要调整顺序
  {
    path: '/h5/misc/:id',
    name: 'misc-show',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'misc-show' */ '@/views/misc/show.vue'),
  },

  // 用户中心
  {
    path: '/h5/user/index',
    name: 'user-index',
    component: () =>
      import(/* webpackChunkName: 'user-index' */ '@/views/user/index.vue'),
  },
  {
    path: '/h5/user/myProfile',
    name: 'user-profile',
    component: () =>
      import(/* webpackChunkName: 'user-profile' */ '@/views/user/profile.vue'),
  },
  {
    path: '/h5/user/update-username',
    name: 'user-update-username',
    component: () =>
      import(
        /* webpackChunkName: 'user-update-username' */ '@/views/user/update-username.vue'
      ),
  },
  {
    path: '/h5/user/showMyPayoff',
    name: 'user-my-payoff',
    component: () =>
      import(
        /* webpackChunkName: 'user-my-payoff' */ '@/views/user/my-payoff.vue'
      ),
  },
  {
    // IMPORTANT: 此处 path 需要兼容旧版 URL,不可随意修改
    path: '/h5/project/showPayoff',
    name: 'user-my-payoff-detail',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'user-my-payoff-detail' */ '@/views/user/my-payoff-detail.vue'
      ),
  },
  {
    path: '/h5/user/myProjects',
    name: 'user-my-projects',
    component: () =>
      import(
        /* webpackChunkName: 'user-show-projects' */ '@/views/user/show-projects.vue'
      ),
  },
  {
    path: '/h5/user/mySupports',
    name: 'user-my-supports',
    component: () =>
      import(
        /* webpackChunkName: 'user-show-projects' */ '@/views/user/show-projects.vue'
      ),
  },
  {
    path: '/h5/user/showBindPhone',
    name: 'user-update-phone',
    component: () =>
      import(
        /* webpackChunkName: 'user-update-phone' */ '@/views/user/update-phone.vue'
      ),
  },
  {
    path: '/h5/user/certification',
    name: 'user-certification',
    component: () =>
      import(
        /* webpackChunkName: 'user-certification' */ '@/views/user/certification.vue'
      ),
  },
  {
    path: '/h5/user/certification-status',
    name: 'user-certification-status',
    component: () =>
      import(
        /* webpackChunkName: 'user-certification-status' */ '@/views/user/certification-status.vue'
      ),
  },

  // 关于
  {
    path: '/h5/about',
    name: 'about',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/about.vue'),
  },

  // 用户服务协议
  {
    path: '/h5/user-services-agreement',
    name: 'user-services-agreement',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'user-services-agreement' */ '@/views/user-services-agreement.vue'
      ),
  },

  // 钱包
  {
    path: '/h5/wallet/list',
    name: 'wallet-list',
    component: () =>
      import(/* webpackChunkName: 'wallet-list' */ '@/views/wallet/list.vue'),
  },
  // 提现详情
  {
    path: '/h5/wallet/detail',
    name: 'wallet-detail',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-detail' */ '@/views/wallet/detail.vue'
      ),
  },
  {
    path: '/h5/wallet/certification-tips',
    name: 'wallet-certification-tips',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-certification-tips' */ '@/views/wallet/certification-tips.vue'
      ),
  },
  // 提现表单
  {
    path: '/h5/wallet/withdraw',
    name: 'wallet-withdraw',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-withdraw' */ '@/views/wallet/withdraw.vue'
      ),
  },
  {
    path: '/h5/baofu2-open-account',
    name: 'baofu2-open-account',
    component: () =>
      import(
        /* webpackChunkName: 'baofu2-open-account' */ '@/views/baofu2/open-account.vue'
      ),
  },
  {
    path: '/h5/wallet/baofu2-withdraw',
    name: 'wallet-baofu2-withdraw',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-baofu2-withdraw' */ '@/views/wallet/baofu2-withdraw.vue'
      ),
  },
  {
    path: '/h5/wallet/baofu-certification',
    name: 'wallet-baofu-certification',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-baofu-certification' */ '@/views/wallet/baofu-certification.vue'
      ),
  },
  {
    path: '/h5/wallet/baofu-card',
    name: 'wallet-baofu-card',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-baofu-card' */ '@/views/wallet/baofu-card.vue'
      ),
  },
  {
    path: '/h5/wallet/ticket',
    name: 'wallet-ticket',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'wallet-ticket' */ '@/views/wallet/ticket.vue'
      ),
  },
  {
    path: '/h5/wallet/baofu-withdraw',
    name: 'wallet-baofu-withdraw',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-baofu-withdraw' */ '@/views/wallet/baofu-withdraw.vue'
      ),
  },
  {
    path: '/h5/wallet/withdraw-accounts',
    name: 'wallet-withdraw-accounts',
    component: () =>
      import(
        /* webpackChunkName: 'wallet-withdraw-accounts' */ '@/views/wallet/withdraw-accounts.vue'
      ),
  },
  {
    path: '/h5/wallet/withdraw-add-account',
    name: 'withdraw-add-account',
    component: () =>
      import(
        /* webpackChunkName: 'withdraw-add-account' */ '@/views/wallet/add-account.vue'
      ),
  },

  // 发布筹款
  {
    path: '/h5/publish/show',
    name: 'publish-show',
    component: () =>
      import(/* webpackChunkName: 'publish-show' */ '@/views/publish/show.vue'),
  },
  {
    path: '/h5/publish/description',
    name: 'publish-description',
    component: () =>
      import(
        /* webpackChunkName: 'publish-description' */ '@/views/publish/description.vue'
      ),
  },
  {
    path: '/h5/publish/payoff',
    name: 'publish-payoff',
    component: () =>
      import(
        /* webpackChunkName: 'publish-payoff' */ '@/views/publish/payoff.vue'
      ),
  },
  {
    path: '/h5/publish/add-payoff',
    name: 'publish-add-payoff',
    component: () =>
      import(
        /* webpackChunkName: 'publish-add-payoff' */ '@/views/publish/add-payoff.vue'
      ),
  },

  // 发布动态
  {
    path: '/h5/publish/updates',
    name: 'publish-updates',
    component: () =>
      import(
        /* webpackChunkName: 'publish-updates' */ '@/views/publish/updates.vue'
      ),
  },

  // 宝付开户
  {
    path: '/h5/baofu/open-account',
    name: 'baofu-open-account',
    component: () =>
      import(
        /* webpackChunkName: 'baofu-open-account' */ '@/views/baofu/open-account.vue'
      ),
  },
  // 调试选项页
  {
    path: '/h5/debug',
    name: 'debug',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'debug' */ '@/views/debug/index.vue'),
  },

  // 查看和清理 localStorage 便于测试
  {
    path: '/h5/debug/clear-storage',
    name: 'debug-clear-storage',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'debug-clear-storage' */ '@/views/debug/clear.vue'
      ),
  },
  {
    path: '/h5/debug/show-storage',
    name: 'debug-show-storage',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'debug-show-storage' */ '@/views/debug/show.vue'
      ),
  },
  // 错误信息页
  {
    path: '/h5/error',
    name: 'debug-error',
    meta: {
      needAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'debug-error' */ '@/views/debug/error.vue'),
  },

  // {
  //   path: '*', component: () => import(/* webpackChunkName: 'errors/404' */ '@/views/errors/404.vue'),
  // },
]

export default routes
