import Vue from 'vue'
import Meta from 'vue-meta'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'
import store from '@/store'
import routes from './routes'
import wx from 'weixin-js-sdk'
import axios from 'axios'
import { isWechat } from '@/utils'

import { DEFAULT_SHARE_IMAGE_URL } from '@/config'

Vue.use(Meta)
Vue.use(Router)

// The middleware for every page of the application.
const globalMiddleware = ['common', 'web-oauth']

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(require.context('@/middleware', false, /.*\.js$/))

const router = createRouter()

sync(store, router)

export default router

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter () {
  const router = new Router({
    scrollBehavior,
    mode: 'history',
    routes,
  })

  router.beforeEach(beforeEach)
  router.afterEach(afterEach)

  return router
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach (to, from, next) {
  let components = []

  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(router.getMatchedComponents({ ...to }))
  } catch (error) {
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload(true)
      return
    }
  }

  // “首页”和“我的”页显示 tabbar，其它页面隐藏
  const shouldShowTabbar = ['index', 'user-index'].includes(to.name)

  store.commit('layout/UPDATE_TABBAR_VISIBLE', shouldShowTabbar)

  if (components.length === 0) {
    return next()
  }

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components)

  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      router.app.setLayout(components[0].layout || '')
    }
    next(...args)
  })
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach (to, from, next) {
  if (isWechat()) {
    // 除支使页之外，使用的是业务公众号，而支付页因为使用的是另一个公众号，在页面组件内单独处理
    if (to.name !== 'project-support-payment') {
      const { origin } = window.location
      axios.get(`weixin/config?url=${origin}${to.fullPath}`)
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: [
              'chooseImage',
              'uploadImage',
              'getLocalImgData',
              'hideMenuItems',
              'showMenuItems',
              'updateAppMessageShareData',
              'updateTimelineShareData',
            ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })

          wx.ready(() => {
            const shareMenuItems = [
              'menuItem:share:appMessage', // 发送给朋友:
              'menuItem:share:timeline', // 分享到朋友圈:
              'menuItem:share:qq', // 分享到QQ:
              'menuItem:share:weiboApp', // 分享到Weibo:
              'menuItem:favorite', // 收藏:
              'menuItem:share:facebook', // 分享到FB:
              'menuItem:share:QZone', // 分享到 QQ 空间
            ]

            if (!['project-view', 'project-viewdetail'].includes(to.name)) {
              // 除筹款概况和详情页外，统一分享应用首页
              wx.hideMenuItems({
                menuList: shareMenuItems,
              })

              const shareData = {
                title: '追梦筹', // 分享标题
                desc: '追梦筹', // 分享描述
                link: `${window.location.origin}/h5`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: DEFAULT_SHARE_IMAGE_URL, // 分享图标
              }

              // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
              wx.updateAppMessageShareData(shareData)

              // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
              wx.updateTimelineShareData(shareData)
            } else {
              wx.showMenuItems({
                menuList: shareMenuItems,
              })
            }
          })
        })
    }
  }

  await router.app.$nextTick()
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware (middleware, to, from, next) {
  const stack = middleware.reverse()

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }

    const middleware = stack.pop()

    if (typeof middleware === 'function') {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }

  _next()
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents (components) {
  return Promise.all(
    components.map(component => {
      return typeof component === 'function' ? component() : component
    }),
  )
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware (components) {
  const middleware = [...globalMiddleware]

  components
    .filter(c => c.middleware)
    .forEach(component => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware)
      }
    })

  return middleware
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return { selector: to.hash }
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1)

  if (component && component.scrollToTop === false) {
    return {}
  }

  return { x: 0, y: 0 }
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware (requireContext) {
  return requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce((guards, [name, guard]) => ({ ...guards, [name]: guard.default }), {})
}
