// state
export const state = {
  isLoading: false,
  isSubmitting: false,
}

// types
export type AxiosState = Partial<typeof state>

// mutations
export const mutations = {
  UPDATE_IS_LOADING (state, val) {
    state.isLoading = val
  },

  UPDATE_IS_SUBMITTING (state, val) {
    state.isSubmitting = val
  },
}
