/**
 * 判断是否是微信
 * @returns {boolean}
 */
export function isWechat (): boolean {
  const ua = navigator.userAgent.toLowerCase()
  return /micromessenger/.test(ua)
}

/**
 * 判断是否是支付宝环境
 * @returns {boolean}
 */
export function isAlipay (): boolean {
  const ua = navigator.userAgent.toLowerCase()
  return /alipayclient/.test(ua)
}

/**
 * 是 ios 微信环境
 */
export function isIosWechat (): boolean {
  return window.__wxjs_is_wkwebview as boolean
}

/**
 *
 * @param url
 */
export function getImageSizeInfo (url): object {
  // 通过 onload 事件获取图片宽高
  const img = new Image()
  img.src = url

  return new Promise((resolve) => {
    img.onload = () => {
      resolve({
        w: img.width,
        h: img.height,
      })
    }
  })
}

/**
 * sleep
 * @param ms
 */
export function sleep (ms): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms))
}
