<template>
  <div id="app">
    <transition
      mode="out-in"
      name="page"
    >
      <component
        :is="layout"
        v-if="layout"
      />
    </transition>
  </div>
</template>

<script>
const APP_NAME = '追梦筹'

// Load layout components dynamically.
const requireContext = require.context('@/layouts', false, /.*\.vue$/)

const layouts = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})

export default {
  data: () => ({
    layout: null,
    defaultLayout: 'default',
  }),

  metaInfo: {
    title: APP_NAME,
  },

  methods: {
    /**
       * Set the application layout.
       *
       * @param {String} layout
       */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }
      this.layout = layouts[layout]
    },
  },
}
</script>
