






export default {
  name: 'LayoutAuth',
}
